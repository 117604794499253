<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading text-dark fw-bold fs-3 my-0">
          Anunciantes <span v-if="$route.query.status == 'INACTIVE'">Inativos</span>
        </h1>
        <!--end::Title-->
        <!--end::Title-->
        <div class="mt-3" v-if="advertisers.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ advertisers.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->Filtrar</a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            :class="{
              'show': isFiltersOpen
            }"
            style="position: absolute; top: 40px; right: 0;"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Busca:</label>
                <p style="font-size: 11px;">(ID, nome, empresa ou usuário)</p>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <template v-if="$route.query.status !== 'INACTIVE'">
                <!--begin::Input group-->
                <div class="mb-5">
                  <!--begin::Label-->
                  <label class="form-label fw-semibold">Verba</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="mb-3">
                    <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" value="" name="only_used" v-model="filters.with_orders" />
                      <label class="form-check-label">Apenas anunciantes com verba</label>
                    </div>
                  </div>
                  <!--end::Input-->
                  <!--begin::Input-->
                  <div>
                    <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" value="" name="only_used" v-model="filters.without_orders" />
                      <label class="form-check-label">Apenas anunciantes sem verba</label>
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Input group-->
              </template>
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
        <!--begin::Secondary button-->
        <!--end::Secondary button-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && advertisers.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else-if="!loader && advertisers.length === 0">
          <p class="text-center pt-5">Nenhum anunciante encontrado</p>
        </template>
        <template v-else>
          <div class="table-responsive">
            <!--begin::Table-->
            <template v-if="$route.query.status == 'INACTIVE'">
              <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-100px">Anunciante</th>
                    <th class="min-w-300px">Usuários Administradores</th>
                    <th class="min-w-50px">Data de Finalização da Última Campanha</th>
                    <th class="min-w-50px">Tempo Desde a Finalização</th>
                    <th class="min-w-70px">Ações</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-semibold text-gray-600">
                  <!--begin::Table row-->
                  <tr v-for="(advertiser, index) in advertisers" :key="index">
                    <td>
                      <span>{{ advertiser.name }}</span>
                    </td>
                    <td>
                      <ul>
                        <li v-for="(item, index) in advertiser.company.admin_users.filter(user => user.phone)" :key="index">
                          <router-link
                            :to="{ name: 'users.show', params: { id: item.id } }"
                            class="text-decoration-underline text-gray-600"
                          >
                            {{ item.name }}
                          </router-link> -
                          <a :href="`https://web.whatsapp.com/send/?phone=${item.phone}`" target="_blank" class="text-success">
                            {{ item.phone | VMask(phoneMask(advertiser.company.country)) }}
                          </a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <span>{{ advertiser.last_campaign_end_date | formatDate }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.time_since_last_campaign_ended }} dias</span>
                    </td>
                    <td>
                      <span>
                        <router-link
                          :to="{ name: 'advertisers.show', params: { id: advertiser.id } }"
                          class="btn btn-sm btn-primary"
                        >
                          <i class="fas fa-eye px-0"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                  <!--end::Table row-->
                </tbody>
                <!--end::Table body-->
              </table>
            </template>
            <template v-else>
              <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-100px">Anunciante</th>
                    <th class="min-w-100px">Website</th>
                    <th class="min-w-70px">Saldo disponível</th>
                    <th class="min-w-70px">Saldo alocado</th>
                    <th class="min-w-70px">Saldo pendente</th>
                    <th class="min-w-70px">Status</th>
                    <th class="min-w-70px">Ações</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-semibold text-gray-600">
                  <!--begin::Table row-->
                  <tr v-for="(advertiser, index) in advertisers" :key="index">
                    <td>
                      <span>{{ advertiser.name }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.website }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.available_amount/100 | formatMoney(advertiser.currency) }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.allocated_amount/100 | formatMoney(advertiser.currency) }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.pending_amount/100 | formatMoney(advertiser.currency) }}</span>
                    </td>
                    <td>
                      <span>{{ advertiser.status | formatStatus }}</span>
                    </td>
                    <td>
                      <span>
                        <router-link
                          :to="{ name: 'advertisers.show', params: { id: advertiser.id } }"
                          class="btn btn-sm btn-primary"
                        >
                          <i class="fas fa-eye px-0"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                  <!--end::Table row-->
                </tbody>
                <!--end::Table body-->
              </table>
            </template>
          </div>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import { mask } from 'vue-the-mask'
import { getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import metadata from 'libphonenumber-js/metadata.full.json'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  directives: { mask },
  components: {
    Button,
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: 'Anunciantes'
    }
  },
  data () {
    return {
      advertisers: [],
      filters: {
        search: '',
        with_orders: false,
        without_orders: false
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      page: 1,
      total: 0
    }
  },
  created () {
    this.getAdvertisers()
  },
  mounted () {
    document.getElementsByClassName('date_picker_date').forEach(item => {
      item.flatpickr({
        dateFormat: 'd/m/Y'
      })
    })
  },
  methods: {
    /**
     * Phone mask
    */
    phoneMask (country) {
      if (!country) return ''
      const exampleNumber = getExampleNumber(country, examples, metadata)
      if (exampleNumber) {
        return `+${exampleNumber.countryCallingCode.replace(/\d/g, '#')} ${exampleNumber.formatNational().replace(/\d/g, '#')}` // Replace all digits with '#'
      }
      return ''
    },
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.advertisers = []
      this.getAdvertisers()
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.loader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          search: this.filters.search,
          page: this.page,
          with_orders: this.filters.with_orders,
          without_orders: this.filters.without_orders,
          status: this.$route.query.status
        })
        this.advertisers = [...this.advertisers, ...advertisers.data]
        this.isLoadMoreVisible = advertisers.meta.current_page !== advertisers.meta.last_page
        this.total = advertisers.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getAdvertisers()
    },
    /**
     * Reset
     */
    reset () {
      this.isFiltersOpen = false
      this.filters.search = ''
      this.filters.with_orders = false
      this.filters.without_orders = false
      this.page = 1
      this.advertisers = []
      this.getAdvertisers()
    }
  }
}
</script>
